<script setup lang="ts">
const emit = defineEmits(["close"]);

const modal = ref<HTMLDivElement | null>(null);

onClickOutside(modal, (event) => {
  event.stopPropagation();
  emit("close");
});
</script>

<template>
  <div class="modal" ref="modal">
    <div class="backdrop" @click="emit('close')"></div>
    <div class="dialog">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/abstracts" as *;
.backdrop {
  position: fixed;
  inset: 0;
  background: $color-black;
  opacity: 0.2;
}
.dialog {
  position: relative;
  z-index: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-top: solid 1px rgba($color-primary, 0.1);
  padding-bottom: max(env(safe-area-inset-bottom), 1rem);
}
</style>
